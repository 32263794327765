
let vm = null;

export default {
  name: "SwiperNextStep",

  data() {
    return {
      activeSlide: 0,
      swiperOptionTop: {
        loop: false,
        loopedSlides: 4, // looped slides should be the same
        spaceBetween: 10,
        autoplay: false,
        allowTouchMove: false,
        effect: "fade",
        fadeEffect: { crossFade: true },
        speed: 1000,
      },
      swiperOptionThumbs: {
        loop: false,
        loopedSlides: 4, // looped slides should be the same
        spaceBetween: 8,
        centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        allowTouchMove: false,
        autoplay: false,
        lazy: {
          loadPrevNext: true,
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            allowTouchMove: true,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            allowTouchMove: true,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        },
        on: {
          // slideChange() {
          //   vm.activeSlides(this.activeIndex);
          //   // console.log(this.activeIndex);
          // },
        },
      },
    };
  },

  methods: {
    // activeSlides(id) {
    //   console.log(id);
    // },
  },
  created() {
    vm = this;
  },
  mounted() {
    this.$nextTick(() => {
      const swiperNextStepPreview = this.$refs.swiperNextStepPreview.$swiper;
      const swiperNextStepThumbs = this.$refs.swiperNextStepThumbs.$swiper;
      swiperNextStepThumbs.controller.control = swiperNextStepPreview;
      swiperNextStepPreview.controller.control = swiperNextStepThumbs;
    });
    if (window.innerWidth > 960) {
      this.swiperOptionThumbs.autoplay = { delay: 5000 };
      this.swiperOptionTop.autoplay = { delay: 5000 };
    }
  },
};
